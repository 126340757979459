import {
  ChargingSessionDetailedStatus,
  ChargingSessionStatus,
  IActivationEntry,
} from '@shared/services/util';
import { StationSource } from '@shared/services/util/station-source-icon.service';

export interface BusinessPartner {
  id: string;
  name: string;
  locations: Array<BusinessPartnerLocation>;
}

export interface BusinessPartnerLocation {
  uuid: string;
  name: string;
}

export interface ActivationResult {
  paging: Paging;
  data: ActivationEntry[];
}

export interface Paging {
  currentPage?: number;
  totalPages?: number;
  itemsPerPage?: number;
  totalItems?: number;
}

export interface ActivationEntry {
  id: string;
  startTime: string;
  stopTime: string;
  stationUuid: string;
  chargepointID: string;
  chargepointUuid: string;
  authType: string;
  authToken: string;
  activationState: string;
  bdrID: string;
  bdrState: string;
  charging: boolean;
  contractID: string;
  sessionState: string;
  status: ChargingSessionStatus;
  errorMessage: string;
  statusDetail: ChargingSessionDetailedStatus;
  stationSource: StationSource;
}

export interface ActivationsStatusesAndPartnersFilters {
  activationStatus: Array<string>;
  businessPartners: Array<BusinessPartner>;
}

export enum SortColumn {
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  EVSE_ID = 'EVSE_ID',
  CONTRACT_ID = 'CONTRACT_ID',
  AUTHENTICATION_SOURCE = 'AUTHENTICATION_SOURCE',
  AUTHENTICATION_TOKEN = 'AUTHENTICATION_TOKEN',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface OrderSet {
  sortColumn: SortColumn;
  sortOrder: SortOrder;
}

export interface FilterSet extends FilterSetBusinessPartner, FilterSetPage, FilterSetAddress {
  dateFrom?: string;
  dateTo?: string;
  statusList?: Array<ChargingSessionStatus>;
  authenticationSources?: Array<string>;
  search?: string;
}

export interface FilterSetBusinessPartner {
  businessPartnerId?: string;
  locationId?: string;
}

export interface FilterSetPage {
  page?: number;
  itemsPerPage?: number;
}

export interface FilterSetAddress {
  country?: string;
  city?: string;
  zipCode?: string;
  streetSearchList?: string[];
}

export enum EAuthenticationSource {
  REMOTE = 'REMOTE',
  OPERATION = 'OPERATION',
  MOBILE = 'MOBILE',
  PAYONE = 'PAYONE',
  HUBJECT_CPO = 'HUBJECT_CPO',
  HUBJECT_EMP = 'HUBJECT_EMP',
  OCPI_CPO = 'OCPI_CPO',
  OCPI_EMP = 'OCPI_EMP',
  RFID = 'RFID',
  PNC = 'PNC',
  PILOT_SIGNAL = 'PILOT_SIGNAL',
  LAN = 'LAN',
  NO_AUTH = 'NO_AUTH',
  UNDEFINED = 'UNDEFINED',
  PAYMENT_TERMINAL = 'PAYMENT_TERMINAL',
  PAYMENT_TERMINAL_STANDALONE = 'PAYMENT_TERMINAL_STANDALONE',
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
}

export interface IActivationResult {
  paging: Paging;
  activationView: IActivationEntry[];
}
