import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testId',
})
export class TestIdPipe implements PipeTransform {
  /**
   * Will transform e.g. "1 GLOBAL ENTRY" to "1_GLOBAL_ENTRY"
   */
  transform(value: string): string {
    if (value === undefined || value === null) {
      return '';
    } else if (typeof value === 'number') {
      return value;
    } else {
      return value.split(' ').join('_');
    }
  }
}
