import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUserLogin } from '@auth/login';
import { resetStores } from '@datorama/akita';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, StorageBroadcastService } from '@shared/services/auth';
import { NotificationLevel, TopNotificationService } from '@shared/services/notification';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import userflow from 'userflow.js';
import { SessionActions } from './session.actions';
import { EWaitResponseType } from './session.model';

@Injectable({
  providedIn: 'root',
})
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private topNotificationService: TopNotificationService,
    private broadcastService: StorageBroadcastService,
    private snackBar: SnackbarService,
    private router: Router
  ) {}

  startLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.startLogin),
      switchMap(action => [
        SessionActions.login({ username: action.username, password: action.password }),
        SessionActions.waitLogin({ value: EWaitResponseType.WAITING }),
      ])
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.login),
      switchMap(action =>
        this.authenticationService.login(action.username, action.password).pipe(
          tap((result: IUserLogin) => this.broadcastService.broadcastLogin(result)),
          switchMap((result: IUserLogin) => {
            return [
              SessionActions.loginSuccess({ userLogin: result }),
              SessionActions.waitLogin({ value: EWaitResponseType.FINISH }),
              SessionActions.closeTopNotification(),
            ];
          }),
          catchError(errorResponse => {
            let message: string;
            const backendError = errorResponse.error;
            switch (backendError.error) {
              case '401': {
                message = this.translate.instant('LOGIN.USERNAME_INCORRECT');
                break;
              }
              case '404': {
                message = this.translate.instant('LOGIN.ENDPOINT_NOT_FOUND');
                break;
              }
              default: {
                message = this.translate.instant('GLOBAL.LOGIN_FAIL_MSG');
              }
            }

            return of(
              SessionActions.showTopNotification({
                notification: {
                  notificationLevel: NotificationLevel.ERROR,
                  message: message,
                },
              }),
              SessionActions.waitLogin({ value: EWaitResponseType.FINISH_WITH_ERROR })
            );
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logout),
      switchMap(() => {
        // needed for closing download progress bar when opened
        this.snackBar.closeSnackbar();
        return this.authenticationService.logout();
      }),
      map(() => {
        this.broadcastService.broadcastLogout();
        return SessionActions.logoutSuccess();
      }),
      catchError(error => {
        this.topNotificationService.showErrorInTopNotification(error);
        return of(SessionActions.logoutError());
      })
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutSuccess, SessionActions.logoutFromBroadcast),
      map(() => {
        userflow.reset();
        resetStores();
        localStorage.removeItem('state');
        this.router.navigate(['/auth/login']);
        return SessionActions.closeTopNotification();
      })
    )
  );

  fetchUserSettingsValueList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.loginSuccess),
      switchMap(() => {
        return this.authenticationService.getSettingValueList();
      }),
      map(result => SessionActions.setValueList({ settings: result })),
      catchError(error => {
        this.topNotificationService.showErrorInTopNotification(error);
        return of(SessionActions.setValueList({ settings: null }));
      })
    )
  );
}
