<eop-image-placeholder
  *ngIf="!groups || groups.length === 0"
  image="assets/img/no-entries-placeholder.svg"
  [text]="'MANAGER.PLEASE_CREATE_TARIFF' | translate"
  width="33%"
  (click)="onCreate()"
>
</eop-image-placeholder>

<eop-grid-list *ngIf="groups && groups.length > 0">
  <innogy-group
    *ngFor="let group of groups"
    [group]="group"
    [editMode]="editMode"
    [iconDisableMode]="iconDisableMode"
    (editGroupClicked)="onEditGroupClicked($event)"
    (deleteGroupClicked)="onDeleteGroupClicked($event)"
    (groupClicked)="onGroupClicked($event)"
  >
  </innogy-group>
</eop-grid-list>
