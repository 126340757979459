import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Language } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import { ILocation, LocationPipe, LocationTypes } from '@shared/pipes';
import { UserSettingsService } from '@shared/services/util';
import { LanguageService } from '@shared/translate/language.service';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IColumn, ITableConfig, ITableData, LinkCellData } from '@widgets/innogy-data-table';

export interface GroupWithMembers {
  id: string;
  name: string;
  groupMembers: Members;
}

export const enum GroupMemberLevel {
  STATIONS,
  PORTS,
}

export interface Members {
  paging: Paging;
  members: GroupMemberDataRow[];
}

export interface Paging {
  currentPage?: number;
  totalPages?: number;
  itemsPerPage?: number;
  totalItems?: number;
}

export interface GroupMemberDataRow {
  stationUuid: string;
  stationId: string;
  portId?: string;
  country: string;
  city: string;
  zipCode: string;
  street: string;
  supplementDE: string;
  supplementEN: string;
}

@Component({
  selector: 'eop-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupMemberComponent implements OnInit {
  @Input() level: GroupMemberLevel = GroupMemberLevel.PORTS;
  @Input() set groupWithMember(group: GroupWithMembers) {
    this.group = group;
    this.headlineLabel = this.updateHeadlineLabel(group);
    this.updateTableData(group && group.groupMembers);
  }
  @Input() hideFirstBorder = false;
  @Input() fixHeight = false;
  @Input() actionLabel = '';
  @Input() actionIcon = '';

  @Output() requireNewData = new EventEmitter<number>();
  @Output() actionClicked = new EventEmitter<boolean>();

  group: GroupWithMembers;
  tableData: ITableData;
  tableConfig: ITableConfig;
  userLanguage: Language;
  headlineLabel: string;

  emptyTableData: ITableData = {
    paging: {
      currentPage: 0,
      totalItems: 0,
      itemsPerPage: 0,
      totalPages: 1,
    },
    data: [],
  };
  readonly ButtonColor = ButtonColor;

  constructor(
    private translate: TranslateService,
    private locationPipe: LocationPipe,
    private userSettingsService: UserSettingsService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.initTable();
    this.getUserLanguage();
  }

  onRequireNewData(page: number): void {
    this.requireNewData.emit(page);
  }

  onActionClicked(): void {
    this.actionClicked.emit(true);
  }

  private initTable(): void {
    const tableConfig: ITableConfig = {
      tableSettings: {
        nextDataSelection: 'scroll',
        checkable: false,
        searchable: false,
        sortable: false,
        uniqueKey: 'chargePointId',
        showChipList: true,
        chipText: 'chargePointId',
        chipsHeadline: this.translate.instant('EDIT_GROUP.SELECTED_CHARGPOINTS'),
        searchPlaceholder: [
          this.translate.instant('GLOBAL.CHARGE_POINT_ID'),
          this.translate.instant('GLOBAL.CHARGING_STATION_ID'),
          this.translate.instant('GLOBAL.EVSE_ID'),
        ],
        height: this.fixHeight ? '250px' : null,
        showEmptyResultsBanner: true,
        testId: 'group-member',
      },
      columns: this.getColumnSettings(this.level),
    };
    this.tableConfig = tableConfig;
  }

  private updateTableData(result: Members) {
    this.tableData = this.convertIntoTableData(result);
  }

  private updateHeadlineLabel(group: GroupWithMembers): string {
    const totalItems =
      group &&
      group.groupMembers &&
      group.groupMembers.paging &&
      group.groupMembers.paging.totalItems;

    const textLevel =
      this.level === GroupMemberLevel.PORTS
        ? totalItems === 1
          ? this.translate.instant('GLOBAL.CHARGE_POINT')
          : this.translate.instant('GLOBAL.CHARGE_POINTS')
        : totalItems === 1
          ? this.translate.instant('GLOBAL.CHARGING_STATION')
          : this.translate.instant('GLOBAL.CHARGING_STATIONS');

    return totalItems && totalItems !== 0 ? textLevel : '';
  }

  private convertIntoTableData(data: Members): ITableData {
    if (data && data.members) {
      const tableData = {
        paging: data.paging,
        data: [],
      };

      tableData.data = data.members.map((dataRow: GroupMemberDataRow) => {
        const chargePortLinkCell: LinkCellData = {
          text: dataRow.portId,
          url: dataRow.stationUuid
            ? '/operate/chargingInfrastructureDetail?uuid=' +
              dataRow.stationUuid +
              '&chargePort=' +
              dataRow.portId
            : null,
        };
        const chargingStationLinkCell: LinkCellData = {
          text: dataRow.stationId,
          url: dataRow.stationUuid
            ? '/operate/chargingInfrastructureDetail?uuid=' +
              dataRow.stationUuid +
              '&chargingStation=' +
              dataRow.stationId
            : null,
        };
        const { zipCode, supplementDE, supplementEN } = dataRow;
        const newCity = this.locationPipe.transform(dataRow as ILocation, LocationTypes.CITY);
        const line1 = this.locationPipe.transform(dataRow as ILocation, LocationTypes.LINE1);
        return {
          chargePortId: chargePortLinkCell,
          chargingStationId: chargingStationLinkCell,
          zipCode: zipCode,
          city: newCity,
          address1: line1,
          address2: this.getAddress2(supplementDE, supplementEN),
        };
      });
      return tableData;
    } else return this.emptyTableData;
  }

  private getUserLanguage() {
    this.userLanguage = this.languageService.getUserLanguageWithFallback();
  }

  private getColumnSettings(level: GroupMemberLevel): IColumn[] {
    const chargePortIdColumn: IColumn = {
      id: 'chargePortId',
      value: this.translate.instant('GLOBAL.CHARGE_POINT_ID'),
      visibleInHeader: true,
      type: 'link',
      sortable: false,
      shouldTextCrop: true,
      showToolTip: true,
    };
    const commonSettings: IColumn[] = [
      {
        id: 'chargingStationId',
        value: this.translate.instant('GLOBAL.CHARGING_STATION_ID'),
        visibleInHeader: true,
        type: 'link',
        sortable: false,
        shouldTextCrop: true,
        showToolTip: true,
      },
      {
        id: 'zipCode',
        value: this.translate.instant('GLOBAL.ZIP'),
        visibleInHeader: true,
        type: 'text',
        showToolTip: true,
        sortable: false,
      },
      {
        id: 'city',
        value: this.translate.instant('GLOBAL.TOWN'),
        visibleInHeader: true,
        type: 'text',
        showToolTip: true,
        sortable: false,
      },

      {
        id: 'address1',
        value: this.translate.instant('GLOBAL.STREET'),
        visibleInHeader: true,
        type: 'text',
        showToolTip: true,
      },
      {
        id: 'address2',
        value: this.translate.instant('GLOBAL.ADDRESS2'),
        visibleInHeader: true,
        type: 'textWithTooltip',
        showToolTip: true,
      },
    ];
    const columnSettings: IColumn[] =
      level === GroupMemberLevel.PORTS
        ? [chargePortIdColumn, ...commonSettings]
        : [...commonSettings];
    return columnSettings;
  }

  private getAddress2(
    supplementLocal: string,
    supplementEN: string
  ): { text: string; tooltip: string } {
    const address2 = this.userSettingsService.getAddress2(supplementLocal, supplementEN);
    return { text: address2.text, tooltip: address2.tooltip };
  }
}
