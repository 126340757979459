import { NgModule } from '@angular/core';
import {
  LocationPipe,
  NumberFormatPipe,
  RoamingChipPipe,
  SanitizerPipe,
  TranslateSystemEmaidPipe,
  UserDatePipe,
  UserDateTimePipe,
  UserDecimalPipe,
  UserTimeUtcOffsetPipe,
} from '@shared/pipes';
import { AidNamePipe } from './aid-name.pipe';
import { EmptyFieldPipe } from './empty-field.pipe';
import { IconUtilPipe } from './icon-util.pipe';
import { PricesChipPipe } from './prices-chip.pipe';
import { ResultsPipe } from './results.pipe';
import { TariffTableRowPipe } from './tariff-table-row.pipe';
import { TestIdPipe } from './test-id.pipe';
import { YearOnlyPipe } from './year-only.pipe';

@NgModule({
  declarations: [
    AidNamePipe,
    IconUtilPipe,
    LocationPipe,
    NumberFormatPipe,
    RoamingChipPipe,
    PricesChipPipe,
    TariffTableRowPipe,
    TranslateSystemEmaidPipe,
    UserDatePipe,
    UserDateTimePipe,
    UserDecimalPipe,
    SanitizerPipe,
    UserTimeUtcOffsetPipe,
    ResultsPipe,
    EmptyFieldPipe,
    YearOnlyPipe,
    TestIdPipe,
  ],
  exports: [
    AidNamePipe,
    IconUtilPipe,
    LocationPipe,
    NumberFormatPipe,
    RoamingChipPipe,
    PricesChipPipe,
    TariffTableRowPipe,
    TranslateSystemEmaidPipe,
    UserDatePipe,
    UserDateTimePipe,
    UserDecimalPipe,
    SanitizerPipe,
    UserTimeUtcOffsetPipe,
    ResultsPipe,
    EmptyFieldPipe,
    YearOnlyPipe,
    TestIdPipe,
  ],
})
export class PipesModule {}
