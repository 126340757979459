<div
  class="file-upload-container"
  [ngStyle]="
    width && height ? { width: width?.toString() + 'px', height: height?.toString() + 'px' } : {}
  "
  dragAndDropUpload
  [allowMultiple]="true"
  (click)="loading || disabled ? null : fileInput.click()"
  (onFileDropped)="loading || disabled ? null : onSelectFiles($event)"
>
  <input
    #fileInput
    hidden
    type="file"
    multiple
    [accept]="fileType"
    (change)="onSelectFiles($event.target.files)"
    (click)="fileInputClick($event.target)"
  />
  <spinner *ngIf="loading"></spinner>
  <label class="body-l" *ngIf="!loading">{{ 'GENERAL.DRAG_AND_DROP' | translate }}</label>
  <span class="file-types-description" *ngIf="!loading">{{
    'CHARGING_STATION_EVENTS.FILES_UP_TO_128' | translate
  }}</span>
</div>
<div *ngIf="infoMessage" class="info-message">
  <eop-icon identifier="info-circle-outlined" [vaylensFont]="true"></eop-icon>
  <span class="body-bold-l">{{ infoMessage }}</span>
</div>
<div class="loaded-files-list">
  <div *ngFor="let file of allFilesData" class="loaded-file">
    <eop-file [file]="file" (onRemoveFile)="removeFile($event)"></eop-file>
  </div>
  <spinner *ngIf="loading"></spinner>
</div>
