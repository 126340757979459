import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDecimalPipe } from '@shared/pipes/user-decimal.pipe';

@Pipe({
  name: 'results',
})
export class ResultsPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private userDecimal: UserDecimalPipe
  ) {}

  transform(value: number): string {
    switch (value) {
      case null: {
        return this.translate.instant('GLOBAL.LOADING_DATA');
      }
      case undefined: {
        return this.translate.instant('GLOBAL.NO_ENTRIES');
      }
      case 0: {
        return this.translate.instant('GLOBAL.NO_ENTRIES');
      }
      case 1: {
        return value + ' ' + this.translate.instant('GLOBAL.RESULT');
      }
      default: {
        return (
          this.userDecimal.transform(value, '1.0-0') +
          ' ' +
          this.translate.instant('GLOBAL.RESULTS')
        );
      }
    }
  }
}
