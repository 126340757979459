import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Language } from '@auth/login';
import { UserDecimalPipe } from '@shared/pipes';
import * as d3 from 'd3';
import { NumberValue } from 'd3-scale';

export interface DataSet {
  label: string;
  value: number;
  color: RectColor;
}

export enum RectColor {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  GREY = 'grey',
}

export interface GroupedData {
  id: string;
  value: number;
  cumulative: number;
  fakeValue: number;
  label: string;
  percent: string;
  color: string;
}

export interface SingleEntityContext {
  id: string;
  title: string;
  tooltipText: string;
}

@Component({
  selector: 'eop-normalized-stacked-bar-chart',
  templateUrl: './normalized-stacked-bar-chart.component.html',
  styleUrls: ['./normalized-stacked-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NormalizedStackedBarChartComponent implements AfterViewInit {
  @ViewChild('chartWrapper') chartWrapper: ElementRef;
  tooltipContext: SingleEntityContext = {
    id: '',
    title: '',
    tooltipText: '',
  };
  @Input() locale: Language;
  @Input() dataSet: DataSet[];
  @Input() tooltipTemplate!: TemplateRef<any>;
  @Output() barClick = new EventEmitter<GroupedData>();
  showTooltip = false;
  protected readonly chartUuid = crypto.randomUUID();
  private tooltip!: d3.Selection<d3.BaseType, {}, null, undefined>;
  private data: DataSet[];
  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private xScale: d3.ScaleLinear<number, number, never>;
  private xScaleFake: d3.ScaleLinear<number, number, never>;

  constructor(
    private readonly element: ElementRef,
    private cdr: ChangeDetectorRef,
    private decimalPipe: UserDecimalPipe
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.removeChart();
    const width = this.getWidthForRender();
    if (this.xScale && this.xScaleFake) {
      this.drawChart(`#stacked-bar-chart-${this.chartUuid}`, this.dataSet, {
        width,
      });
      this.setHoverAndTooltip();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const width = this.getWidthForRender();
      this.drawChart(`#stacked-bar-chart-${this.chartUuid}`, this.dataSet, {
        width,
      });
      this.setHoverAndTooltip();
    });
  }

  private getWidthForRender(): number {
    return this.chartWrapper.nativeElement.offsetWidth;
  }

  private removeChart() {
    d3.selectAll(`#stacked-bar-chart-${this.chartUuid}`).selectChild('svg').remove();
  }

  private drawChart(bind: string, data: DataSet[], config) {
    this.data = data.filter(d => d.value !== 0);
    config = {
      margin: { top: 20, right: 0, bottom: 20, left: 0 },
      height: 70,
      barHeight: 60,
      ...config,
    };
    const { margin, width, height, barHeight } = config;
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;
    const halfBarHeight = barHeight / 2;
    const total = d3.sum(this.data, d => d.value);

    // set up scales for horizontal placement
    const xScale = this.getXScale([0, total], [0, w]);
    this.xScale = xScale;

    const groupedData = this.groupData(this.data, total);

    // Refresh xscale after fake data
    const fakeTotal = d3.sum(groupedData, d => d.fakeValue);
    let xScaleFake = this.getXScale([0, fakeTotal], [0, w]);
    this.xScaleFake = xScaleFake;
    const self = this;

    this.drawSvg(
      bind,
      width,
      height,
      margin,
      halfBarHeight,
      barHeight,
      h,
      groupedData,
      xScaleFake,
      self
    );
  }

  private getXScale(
    domain: Iterable<NumberValue>,
    range: Iterable<number>
  ): d3.ScaleLinear<number, number, never> {
    return d3.scaleLinear().domain(domain).range(range);
  }

  private drawSvg(
    bind: string,
    width: number,
    height: number,
    margin: { top: number; right: number; bottom: number; left: number },
    halfBarHeight: number,
    barHeight: number,
    h: number,
    groupedData: GroupedData[],
    xScale: d3.ScaleLinear<number, number, never>,
    self: NormalizedStackedBarChartComponent
  ) {
    // create svg in div
    this.svg = d3
      .select(bind)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // stack rect for each data value
    this.svg
      .selectAll('rect')
      .data(groupedData)
      .enter()
      // create main rect
      .append('rect')
      .attr('class', 'rect-stacked')
      .attr('x', d => {
        return xScale(d.cumulative);
      })
      .attr('y', h / 2 - halfBarHeight)
      .attr('height', barHeight)
      .attr('width', d => {
        return xScale(d.fakeValue);
      })
      .attr('class', d => `${d.label.toLowerCase().replaceAll(' ', '-')} has-tooltip`)
      .style('fill', (d, i) => {
        if (i === 0) {
          self.setGradient();
        }
        return `url(#gradient-${d.color})`;
      })
      .select('rect')
      .data(groupedData)
      .enter()
      // create separator rect
      .append('rect')
      .attr('height', barHeight)
      .attr('width', 2)
      .attr('x', d => {
        return xScale(d.cumulative) - 1;
      })
      .attr('y', h / 2 - halfBarHeight)
      .style('fill', 'white')
      .select('rect')
      .data(groupedData)
      .enter()
      // create upper line rect
      .append('rect')
      .attr('class', d => `${d.color}-stacked-line`)
      .attr('x', d => {
        return xScale(d.cumulative) + 0.5;
      })
      .attr('y', h / 2 - halfBarHeight - 3)
      .attr('height', 7)
      .attr('width', (d, i) => {
        if (i === self.data.length - 1) {
          return xScale(d.fakeValue);
        }
        return xScale(d.fakeValue) - 1;
      })
      .attr('rx', '3');

    // add values on bar
    this.svg
      .selectAll('.text-value')
      .data(groupedData)
      .enter()
      .append('text')
      .attr('class', 'text-percentage-value heading-m')
      .attr('text-anchor', 'start')
      .attr('x', d => xScale(d.cumulative) + 5)
      .attr('y', h / 2 + 5)
      .text(d => {
        if (xScale(d.value) < 80) {
          return '';
        }
        return `${d.percent} %`;
      })
      .on('click', (mouseEvent: MouseEvent, d: GroupedData) => {
        this.barClick.emit(d);
      })
      .append('svg:tspan')
      .attr('x', d => xScale(d.cumulative) + 5)
      .attr('y', h / 2 + 20)
      .text(d => {
        if (xScale(d.value) < 80) {
          return '';
        }
        return `${d.label}`;
      })
      .attr('class', 'text-description label');
  }

  private getRectWidth(width: number): number {
    if (width < 10) {
      return 10;
    }
    return width;
  }

  private setGradient() {
    const self = this;
    //create gradients
    const gradient = this.svg
      .append('defs')
      .selectAll('linearGradient') // Creates the initial selection of linear gradients
      .data(self.data)
      .enter() // Binds new linearGradient elements for each data point
      .append('linearGradient')
      .attr('id', d => `gradient-${d.color}`) // Create a unique data-driven id for each linearGradient
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('class', d => `gradient gradient-${d.color}`);

    gradient.append('stop').attr('offset', '100%').attr('class', 'gradient white-stop');
  }

  private setHoverAndTooltip() {
    const self = this;
    this.svg
      .selectAll('rect.has-tooltip')
      .on('mousemove', (mouseEvent: MouseEvent, d: GroupedData) => {
        const showTooltipOnElement = self.xScaleFake(d.value) < 80;
        if (!this.showTooltip && showTooltipOnElement) {
          this.showTooltip = true;
          this.cdr.detectChanges();
          this.initTooltip();
        }
        if (this.tooltipContext.id !== d.id) {
          this.tooltipContext = {
            id: d.id,
            title: d.label,
            tooltipText: `${d.percent} %`,
          };
          this.cdr.detectChanges();
        }

        if (showTooltipOnElement) {
          this.tooltip
            .style('left', mouseEvent.offsetX + 15 + 'px')
            .style('top', mouseEvent.offsetY - 10 + 'px');

          d3.selectAll(`#gradient-${d.color}`)
            .select(`stop.gradient-${d.color}`)
            .attr('class', `gradient gradient-${d.color} increased-gradient`);
        }
      })
      .on('mouseout', (mouseEvent: MouseEvent, d: GroupedData) => {
        this.showTooltip = false;
        d3.selectAll(`#gradient-${d.color}`)
          .select(`stop.gradient-${d.color}`)
          .attr('class', `gradient gradient-${d.color}`);
        this.cdr.detectChanges();
      })
      .on('click', (mouseEvent: MouseEvent, d: GroupedData) => {
        this.barClick.emit(d);
      });
  }

  private initTooltip(): void {
    this.tooltip = this.getHostElementSelection().select('emob-tooltip');
  }

  private getHostElementSelection(): d3.Selection<HTMLElement, {}, null, undefined> {
    return d3.select(this.element.nativeElement);
  }

  private groupData(data: DataSet[], total: number): GroupedData[] {
    const dataWithNoFakeValue = data.find(item => this.getRectWidth(this.xScale(item.value)) > 10);
    let noFakeValueSample: { width: number; value: number } = {
      width: this.xScale(dataWithNoFakeValue.value),
      value: dataWithNoFakeValue.value,
    };

    // use scale to get percent values
    const percent = d3.scaleLinear().domain([0, total]).range([0, 100]);
    // also get mapping for next placement
    let cumulative = 0;
    let fakeCumulative: number = 0;
    let legendPercentageSummary = 0;
    return data.map(d => {
      cumulative +=
        this.getRectWidth(this.xScale(d.value)) <= 10
          ? this.calculateFakeValue(noFakeValueSample)
          : d.value;
      fakeCumulative += this.calculateFakeValue(noFakeValueSample);

      const roundedPercentageValue =
        parseFloat(percent(d.value).toFixed(1)) !== 0
          ? parseFloat(percent(d.value).toFixed(1))
          : 0.1;
      legendPercentageSummary = legendPercentageSummary + roundedPercentageValue;
      const adjustedPercentageValue =
        100 - legendPercentageSummary < 0
          ? Math.round(10 * (roundedPercentageValue + (100 - legendPercentageSummary))) / 10
          : roundedPercentageValue; //it decreases last value by difference when sum is not 100% - better idea is maybe decrease highest number

      return {
        id: crypto.randomUUID(),
        value: d.value,
        fakeValue:
          this.getRectWidth(this.xScale(d.value)) <= 10
            ? this.calculateFakeValue(noFakeValueSample)
            : d.value,
        // want the cumulative to set start of rect value
        cumulative:
          cumulative -
          (this.getRectWidth(this.xScale(d.value)) <= 10
            ? this.calculateFakeValue(noFakeValueSample)
            : d.value),
        label: d.label,
        percent: this.decimalPipe.transform(adjustedPercentageValue, '1.0-1'),
        color: d.color,
      };
    });
  }

  private calculateFakeValue(noFakeValueSample: { width: number; value: number }): number {
    return (noFakeValueSample.value * 10) / noFakeValueSample.width;
  }
}
