<div
  class="group"
  [class.disabled]="group.disabled"
  [class.selected]="selected && !group.disabled && !editMode"
  (click)="select()"
  [matTooltip]="group.tooltip"
>
  <div class="icon-box">
    <div class="icon">
      <eop-icon [identifier]="group.icon || 'share-outlined'" [vaylensFont]="true"> </eop-icon>
    </div>
  </div>
  <div class="content-box">
    <div>
      <div class="body-bold-l group-name" [attr.data-test]="'group-name'">
        {{ group.name }}
      </div>
      <div class="group-label" class="label-bold" [matTooltip]="group.description">
        {{ group.description }}
      </div>
    </div>
    <div class="description">
      <div class="group-chips">
        <div class="chip" *ngFor="let chip of group.chipData">
          <eop-innogy-chips-lite
            [text]="chip.value"
            [chipColor]="chip.color"
            [title]="chip.title"
            [emphasized]="chip.emphasized"
          >
          </eop-innogy-chips-lite>
        </div>
      </div>
    </div>
    <div
      *ngIf="group.additionalDescription"
      class="additional-description"
      [attr.data-test]="'group-additional-description'"
    >
      {{ group.additionalDescription }}
    </div>
  </div>
  <div class="additional-box">
    <ng-container *ngIf="editMode && !group.disabled">
      <button
        *ngIf="group.editable !== false || iconDisableMode !== IconDisableModes.HIDE"
        eopButton
        [condensed]="true"
        [color]="ButtonColor.OUTLINE_GREY"
        [iconButton]="true"
        [tooltip]="editIconTooltip | translate"
        [leftIcon]="'edit-2-outlined'"
        [disabled]="isDeleteEditDisabled"
        [attr.data-test]="'edit-group-' + group.name"
        (click)="editGroup(); $event.stopPropagation()"
      ></button>
      <button
        *ngIf="
          group.deletable === undefined ||
          group.deletable === true ||
          iconDisableMode !== IconDisableModes.HIDE
        "
        eopButton
        [condensed]="true"
        [color]="ButtonColor.OUTLINE_ERROR"
        [iconButton]="true"
        [tooltip]="deleteIconTooltip | translate"
        [leftIcon]="'trash-outlined'"
        [disabled]="isDeleteButtonDisabled"
        [attr.data-test]="'delete-group-' + group.name"
        (click)="deleteGroup()"
      ></button>
    </ng-container>
    <ng-container *ngIf="!editMode || (editMode && group.disabled)">
      <div class="icon" *ngFor="let additionalIcon of group.additionalIcons | slice: 0 : 3">
        <eop-icon
          [tooltip]="additionalIcon.additionalIconTooltip | translate"
          [color]="additionalIcon.additionalIconColor"
          [identifier]="additionalIcon.additionalIcon"
          [matTooltipClass]="additionalIcon.matTooltipClass"
        >
        </eop-icon>
      </div>
      <div *ngIf="group.counter && !group.additionalIcons">
        <eop-innogy-chips-lite
          [text]="group.counter + ''"
          [chipColor]="selected ? ChipLiteColor.BRANDING : ChipLiteColor.DEFAULT"
          [chipSize]="ChipLiteSize.SMALL"
          [emphasized]="true"
        >
        </eop-innogy-chips-lite>
      </div>
    </ng-container>
  </div>
</div>
