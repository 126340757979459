import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '@shared/services/notification';
import { SessionFacade } from '@shared/store/session.facade';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LogoutIfNotAuthenticatedInterceptor implements HttpInterceptor {
  readonly ITEM_GW_TOKEN_EXPIRED_MESSAGE = 'Token has expired';

  constructor(
    private matDialog: MatDialog,
    private sessionFacade: SessionFacade,
    private sharedReferenceService: SharedRefService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          const loginRequest = request.url.indexOf('/login') >= 0;
          const passwordChange = request.url.indexOf('/reset-password') >= 0;
          const currentUserRequest = request.url.indexOf('/current-user') >= 0;
          if (
            err instanceof HttpErrorResponse &&
            !loginRequest &&
            !passwordChange &&
            !currentUserRequest &&
            err.status === 401
          ) {
            this.closeAllOpenModalsAndOverlays();
            this.sessionFacade.logout();
            if (err.error?.message?.includes(this.ITEM_GW_TOKEN_EXPIRED_MESSAGE)) {
              this.showTokenExpiredNotification();
            }
          }
        }
      )
    );
  }

  private closeAllOpenModalsAndOverlays(): void {
    this.matDialog.closeAll();
    this.sharedReferenceService.closeAllModals();
    this.sharedReferenceService.closeAllOverlays(true);
  }

  private showTokenExpiredNotification() {
    // timeout is needed because of closing animation - snackbar close is called in session effect logout$
    setTimeout(() => {
      this.snackbar.openSnackbar(
        this.translate.instant('GLOBAL.SESSION_EXPIRED'),
        NotificationLevel.INFO,
        9000
      );
    }, 1000);
  }
}
