import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '@shared/services/notification';
import {
  DownloadData,
  DownloadProgress,
} from '@widgets/download-progress/download-progress.component';
import { SnackbarComponent } from '@widgets/snackbar';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SnackbarService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  openSuccessSnackbar(): void {
    this.openSnackbar(this.translate.instant('GLOBAL.OPERATION_SUCCESSFUL'));
  }

  openSnackbar(message: string, level = NotificationLevel.SUCCESS, duration = 3000): void {
    let panelClass: string;
    switch (level) {
      case NotificationLevel.SUCCESS:
        panelClass = 'success-snackbar';
        break;
      case NotificationLevel.ERROR:
        panelClass = 'error-snackbar';
        break;
      case NotificationLevel.WARNING:
        panelClass = 'warning-snackbar';
        break;
      case NotificationLevel.INFO:
        panelClass = 'info-snackbar';
        break;
    }

    setTimeout(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: duration,
        data: { message: message },
        panelClass: [panelClass],
      });
    });
  }

  openDownloadProgressSnackbar(
    component: ComponentType<unknown>,
    progress$: BehaviorSubject<DownloadProgress>,
    download$: Subject<DownloadData>
  ): void {
    this.snackBar.openFromComponent(component, {
      panelClass: ['download-progress-snackbar'],
      data: { progress$, download$ },
    });
  }

  closeSnackbar() {
    this.snackBar.dismiss();
  }
}
